import React from 'react';

function Error() {
  return (
    <div>
      Ludopathes Error
    </div>
  );
}

export default Error;
