import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, matchPath } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';
import './navlink.css';

function NavLink({ to, children, external }) {
  const { pathname } = useLocation();
  const match = matchPath(pathname, to);

  return (
    <li className={match ? 'active' : ''}>
      <a
        href={to}
        target={external ? '_blank' : ''}
        rel={external ? 'noreferrer' : ''}
      >
        {children}
        {external ? (
          <span>
            &nbsp;
            <FontAwesomeIcon icon={faSquareArrowUpRight} />
          </span>
        ) : ''}
      </a>
    </li>
  );
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  external: PropTypes.bool,
};

NavLink.defaultProps = {
  external: false,
};

export default NavLink;
