import React from 'react';
// import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import './home.module.css';

function Home() {
  return (
    <div>
      <h1>C&apos;est quoi? c&apos;est qui?</h1>
      <p>
        C&apos;est une association qui a été créée pour permettre aux
        adultes de décompresser de leur semaine, autour de jeux de
        société, avec des joueurs novices comme experts, pour découvrir.
        partager, rire, rencontrer du monde.
      </p>
      <p>
        Bref, vous l&apos;aurez compris: pour S&apos;AMUSER.
      </p>
      <p>
        Venez avec ou sans jeux, avec ou sans miam/boire avec ou sans ami..
        mais avec votre bonne humeur.
      </p>
      {/* <Carousel
        autoPlay
        showStatus={false}
        showThumbs={false}
        dynamicHeight
      >
        <div>
          <img src="images/carousel/318001892_462456562704742_5993032809294929152_n.jpg" alt="" />
          <p className="legend">Get On Board</p>
        </div>
        <div>
          <img src="images/carousel/318136473_462456686038063_4562275426404087178_n.jpg" alt="" />
          <p className="legend">HeroQuest</p>
        </div>
        <div>
          <img src="images/carousel/322598785_479900440991526_6604238269630822158_n.jpg" alt="" />
        </div>
        <div>
          <img src="images/carousel/324469297_1060535654810615_4613998647094511949_n.jpg" alt="" />
          <p className="legend">Res Arcana</p>
        </div>
      </Carousel> */}
    </div>
  );
}

export default Home;
