import React from 'react';

import styles from './footer.module.css';

function Footer() {
  return (
    <footer>
      <div className={styles.container}>
        <div className={styles.trademark}>
          <p>©2022-23 - La revanche des ludopathes</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
