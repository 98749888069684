import React from 'react';
import About from '../pages/about';
import Contact from '../pages/contact';
import Events from '../pages/events';
import Error from '../pages/error';
import Games from '../pages/games';
import Home from '../pages/home';
import Layout from '../layouts/mainLayout';

const routes = [
  {
    path: '/',
    element: <Layout><Home /></Layout>,
  },
  {
    path: '/about',
    element: <Layout><About /></Layout>,
  },
  {
    path: '/events',
    element: <Layout><Events /></Layout>,
  },
  {
    path: '/contact',
    element: <Layout><Contact /></Layout>,
  },
  {
    path: '/games',
    element: <Games />,
  },
  {
    path: '/*',
    element: <Layout><Error /></Layout>,
  },
];

export default routes;
