/* eslint-disable react/prop-types */
import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import style from './mainLayout.module.css';

function Layout({ children }) {
  return (
    <div className={style.appContainer}>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default Layout;
