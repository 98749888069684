import React from 'react';
import style from './header.module.css';
import NavLink from './navlink';

function Header() {
  return (
    <header>
      <div className={style.container}>
        <div className={style.logo}>
          <img width="100" height="100" alt="logo" src="./images/logo-removebg-preview.png" />
          La Revanche des Ludopathes
        </div>
        <nav className={style.nav}>
          <ul>
            <NavLink to="/">Accueil</NavLink>
            <NavLink to="/about">L&apos;association</NavLink>
            <NavLink to="/events">Événements</NavLink>
            <NavLink to="https://www.myludo.fr/#!/profil/la-revanche-des-ludopathes-48190/collection" external>Nos jeux</NavLink>
            <NavLink to="/contact">Contact</NavLink>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
