import React from 'react';
import {
  differenceInDays, isBefore, format, parseISO,
} from 'date-fns';
import {
  Table, Thead, Tbody, Tr, Th, Td,
} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { fr } from 'date-fns/locale';
import style from './events.module.css';
import events from '../data/events.json';
// import Event from '../components/event';

const formatedEvents = events.map((event) => ({
  ...event,
  parsedDate: parseISO(event.date),
}));

const today = new Date();
const pastEvents = formatedEvents.filter((event) => isBefore(event.parsedDate, today)).sort((a, b) => differenceInDays(b.parsedDate, a.parsedDate));
const upcommingEvents = formatedEvents.filter((event) => !isBefore(event.parsedDate, today)).sort((a, b) => differenceInDays(a.parsedDate, b.parsedDate));

// const displayEvents = (event) => <Event key={event.date + event.lieu} event={event} />;

function Events() {
  return (
    <div className={style.container}>
      <h1>Nos événements</h1>
      <section>
        <h2>Événements à venir</h2>
        <Table>
          <Thead>
            <Tr>
              <Th className={style.tableHeader}>Date</Th>
              <Th className={style.tableHeader}>Lieu</Th>
              <Th>Infos</Th>
            </Tr>
          </Thead>
          <Tbody>
            {upcommingEvents.map((event) => (
              <Tr key={event.date + event.lieu}>
                <Td>{format(parseISO(event.date), 'dd MMMM yyyy ', { locale: fr })}</Td>
                <Td>{event.lieu}</Td>
                <Td>{event.programme}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </section>
      <section>
        <h2>Événements passés</h2>
        <Table>
          <Thead>
            <Tr>
              <Th className={style.tableHeader}>Date</Th>
              <Th className={style.tableHeader}>Lieu</Th>
              <Th>Infos</Th>
            </Tr>
          </Thead>
          <Tbody>
            {pastEvents.map((event) => (
              <Tr key={event.date + event.lieu}>
                <Td>{format(parseISO(event.date), 'dd MMMM yyyy ', { locale: fr })}</Td>
                <Td>{event.lieu}</Td>
                <Td>{event.programme}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </section>
    </div>
  );
}

export default Events;
