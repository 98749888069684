import React from 'react';
import style from './about.module.css';

function About() {
  return (
    <div className={style.container}>
      <h1>La revanche des ludopathes</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mauris neque, tincidunt malesuada rhoncus in, dictum ac mi.
        Suspendisse potenti. Interdum et malesuada fames ac ante ipsum primis in faucibus.
        Pellentesque tempor eget ipsum non tincidunt. Morbi vel libero aliquet, commodo turpis quis, facilisis elit.
        In rhoncus, nisl a suscipit luctus, arcu augue tincidunt dolor, a consectetur lorem lacus eget mauris. Duis placerat neque et metus maximus, ut gravida eros commodo.
        Donec auctor risus eget bibendum convallis. Praesent interdum elit a eros molestie commodo. Maecenas et malesuada lacus.
        Curabitur ut nulla dignissim, laoreet turpis nec, auctor urna. Maecenas efficitur nulla id lectus semper finibus.
        Nulla elementum placerat urna, eget pharetra purus. Donec iaculis porttitor enim vitae tristique.
      </p>
      <p>
        Suspendisse vestibulum pellentesque accumsan. Fusce ut lectus quis risus aliquet lobortis.
        Suspendisse elementum turpis sed velit dignissim iaculis. Phasellus id purus nulla.
        Nunc ut urna non urna tempor pulvinar eget sed erat. Integer ante neque, interdum id rhoncus at, elementum id sapien.
        Ut velit justo, vestibulum ac justo eget, fermentum lobortis est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
        Curabitur non sapien vitae dui bibendum lacinia. Ut vitae mauris maximus, porta purus tempor, vehicula nunc.
      </p>
      <p>
        Integer rutrum nulla in lacus maximus, luctus ultricies libero facilisis.
        Etiam malesuada non elit at imperdiet. Phasellus sapien sem, dignissim eu risus id, maximus convallis risus.
        Sed blandit arcu purus, sit amet accumsan ante suscipit eget. Cras ac tortor eget ante finibus porttitor eu a arcu.
        Sed elementum elit eget velit iaculis tristique. Maecenas tincidunt sed est ut dignissim. Vestibulum a semper massa. In sed euismod orci.
        Maecenas ornare interdum risus, quis sodales enim finibus commodo. Nam sit amet volutpat libero, vel auctor odio. Morbi interdum malesuada vulputate.
        Fusce eu dui dictum, tristique tortor et, tempor metus.
      </p>
      <p>
        Sed tristique ullamcorper suscipit. Aenean augue tortor, efficitur ac lectus ut, suscipit pretium diam.
        Maecenas mi metus, porttitor eu dapibus at, imperdiet in lectus. Nullam semper, velit in mattis lacinia, augue neque ornare urna, at mattis diam ante et enim.
        Fusce dui ante, suscipit eget fermentum id, scelerisque luctus justo. Donec vitae sollicitudin neque. Vivamus egestas metus et posuere placerat.
        Cras laoreet at erat sagittis volutpat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
        Quisque pretium orci at tortor interdum accumsan. Donec commodo diam vel ante hendrerit volutpat. Donec at fringilla libero, quis condimentum neque.
        Vivamus eu sapien nisl. Praesent purus ligula, imperdiet vel magna sit amet, ultricies malesuada magna.
      </p>
      <p>
        Ut consectetur porttitor turpis, at posuere orci maximus eget. Curabitur elementum tortor sem, et pellentesque est tempor non.
        Cras at sapien faucibus, ultricies diam eu, malesuada nisl. Suspendisse molestie rhoncus risus, et tristique nisi consequat eu.
        Etiam luctus vestibulum enim quis ornare. In ut libero vitae enim mollis ultricies vel sed orci.
        Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aliquam sagittis vulputate eros, eleifend aliquam elit semper non.
        Mauris hendrerit ornare neque, ac dapibus neque cursus ultrices.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mauris neque, tincidunt malesuada rhoncus in, dictum ac mi.
        Suspendisse potenti. Interdum et malesuada fames ac ante ipsum primis in faucibus.
        Pellentesque tempor eget ipsum non tincidunt. Morbi vel libero aliquet, commodo turpis quis, facilisis elit.
        In rhoncus, nisl a suscipit luctus, arcu augue tincidunt dolor, a consectetur lorem lacus eget mauris. Duis placerat neque et metus maximus, ut gravida eros commodo.
        Donec auctor risus eget bibendum convallis. Praesent interdum elit a eros molestie commodo. Maecenas et malesuada lacus.
        Curabitur ut nulla dignissim, laoreet turpis nec, auctor urna. Maecenas efficitur nulla id lectus semper finibus.
        Nulla elementum placerat urna, eget pharetra purus. Donec iaculis porttitor enim vitae tristique.
      </p>
      <p>
        Suspendisse vestibulum pellentesque accumsan. Fusce ut lectus quis risus aliquet lobortis.
        Suspendisse elementum turpis sed velit dignissim iaculis. Phasellus id purus nulla.
        Nunc ut urna non urna tempor pulvinar eget sed erat. Integer ante neque, interdum id rhoncus at, elementum id sapien.
        Ut velit justo, vestibulum ac justo eget, fermentum lobortis est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
        Curabitur non sapien vitae dui bibendum lacinia. Ut vitae mauris maximus, porta purus tempor, vehicula nunc.
      </p>
      <p>
        Integer rutrum nulla in lacus maximus, luctus ultricies libero facilisis.
        Etiam malesuada non elit at imperdiet. Phasellus sapien sem, dignissim eu risus id, maximus convallis risus.
        Sed blandit arcu purus, sit amet accumsan ante suscipit eget. Cras ac tortor eget ante finibus porttitor eu a arcu.
        Sed elementum elit eget velit iaculis tristique. Maecenas tincidunt sed est ut dignissim. Vestibulum a semper massa. In sed euismod orci.
        Maecenas ornare interdum risus, quis sodales enim finibus commodo. Nam sit amet volutpat libero, vel auctor odio. Morbi interdum malesuada vulputate.
        Fusce eu dui dictum, tristique tortor et, tempor metus.
      </p>
      <p>
        Sed tristique ullamcorper suscipit. Aenean augue tortor, efficitur ac lectus ut, suscipit pretium diam.
        Maecenas mi metus, porttitor eu dapibus at, imperdiet in lectus. Nullam semper, velit in mattis lacinia, augue neque ornare urna, at mattis diam ante et enim.
        Fusce dui ante, suscipit eget fermentum id, scelerisque luctus justo. Donec vitae sollicitudin neque. Vivamus egestas metus et posuere placerat.
        Cras laoreet at erat sagittis volutpat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
        Quisque pretium orci at tortor interdum accumsan. Donec commodo diam vel ante hendrerit volutpat. Donec at fringilla libero, quis condimentum neque.
        Vivamus eu sapien nisl. Praesent purus ligula, imperdiet vel magna sit amet, ultricies malesuada magna.
      </p>
      <p>
        Ut consectetur porttitor turpis, at posuere orci maximus eget. Curabitur elementum tortor sem, et pellentesque est tempor non.
        Cras at sapien faucibus, ultricies diam eu, malesuada nisl. Suspendisse molestie rhoncus risus, et tristique nisi consequat eu.
        Etiam luctus vestibulum enim quis ornare. In ut libero vitae enim mollis ultricies vel sed orci.
        Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aliquam sagittis vulputate eros, eleifend aliquam elit semper non.
        Mauris hendrerit ornare neque, ac dapibus neque cursus ultrices.
      </p>
    </div>
  );
}

export default About;
