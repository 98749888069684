import React from 'react';

function Contact() {
  return (
    <div>
      <h1>Pour nous contacter / suivre / trouver</h1>
      <p>Mail</p>
      <p>Facebook</p>
      <p>plan poterie</p>
    </div>
  );
}

export default Contact;
